<template>
  <div class="information">
    <el-image
      :style="item_data.img_url ? 'visibility:visible;' : 'visibility:hidden;'"
      :src="item_data.img_url"
      fit="cover"
      class="information-img"
    ></el-image>
    <div class="information-txt">
      <div @click="viewDetail">{{ item_data.title }}</div>
      <div>{{ item_data.created_time | secondFormat("LL") }}</div>
      <div>{{item_data.content | fullTextFormat(250)}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "information",
  props: {
    item_data: {
      //跨境电商资讯模块
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    // 查看详情
    viewDetail() {
      let param = {
          id: this.item_data.id
      };
      this.$emit("viewDetail", param);
    },
  },
};
</script>

<style scoped lang="less">
.information {
  padding: 0 16px;
  width: 588px;
  height: 142px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(178, 178, 178, 0.47);
  box-sizing: border-box;
  display: flex;
  .information-img {
    width: 200px;
    height: 142px;
    flex-shrink: 0;
    padding: 14px 0 16px;
  }
  .information-txt {
    margin-left: 16px;
    padding: 12px 0;
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #333333;
    }
    > div:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    > div:nth-child(1):hover {
      color: #0d6bb9;
    }
    > div:nth-child(2) {
      font-size: 12px;
      color: #999999;
      margin-top: 8px;
    }
    > div:nth-child(3) {
      font-size: 14px;
      margin-top: 16px;
      min-height: 19px;
      max-height: 38px;
    }
  }
}
</style>
