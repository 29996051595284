<template>
  <div class="video">
    <videoPlay
      ref="playVideo"
      :videoId="item_data.aliyun_id"
      class="palyVideo"
      style="margin-bottom: 5px; margin-top: 2px"
    >
      <al-image
        :fit="'cover'"
        :src="item_data.cover_url"
        class="coverImg"
      ></al-image>
      <div class="mark">
        <i
          class="el-icon-video-play"
          @click="playVideo"
        ></i>
      </div>
    </videoPlay>
    <div class="video-txt" @click="viewDetail">{{item_data.name || ''}}</div>
  </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
  name: "eCommerceVideo",
  components: { videoPlay },
  props: {
    item_data: {  //跨境电商视频模块
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    playVideo() {
      if (this.IS_LOGIN) {
        console.log(this.item_data);
        if (this.item_data.name) this.$refs.playVideo.startPlay(this.item_data.aliyun_id);
        this.$store.dispatch('API_company/play_count_Add',{ video_id: this.item_data.id})
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => { 
            if (this.item_data.name) this.$refs.playVideo.startPlay(this.item_data.aliyun_id);
          },
        });
      }
    },
    // 查看详情
    viewDetail(){
      // let param = {}
      // this.$emit('viewDetail', param);
    },
  },
};
</script>

<style scoped lang="less">
.video {
  width: 282px;
  height: 219px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(178, 178, 178, 0.47);
  box-sizing: border-box;
  padding: 16px;
  .palyVideo {
    width: 250px;
    height: 126px;
    position: relative;
    .coverImg {
      width: 250px;
      height: 126px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .mark {
      width: 250px;
      height: 126px;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      text-align: center;
      cursor: pointer;
      .el-icon-video-play {
        font-size: 34px;
        color: #aaa;
        line-height: 126px;
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
  .video-txt {
    font-size: 14px;
    padding-right: 10px;
    color: #333333;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-top: 4px;
    // cursor: pointer;
  }
  // .video-txt:hover {
  //   color: #0d6bb9;
  // }
}
</style>
